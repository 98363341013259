.list-container {
  margin: 0px 40px;
  padding: 49.17px 0px 120px 0px;
}

.confirm-heading {
  font-family: Noto Sans CJK JP;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  cursor: text;
  color: #121958;
  font-weight: bold;
}

.list-confirm-details {
  margin: 0;
  padding-left: 2rem;
  font-size: 18px;
}

.list-confirm-details > li {
  margin: 0.5rem 0;
  font-size: 14px;
}

.table-mail-config-container {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding-bottom: 2rem;
  padding-top: 1rem;
  @media (max-width: 475px) {
    margin: 1rem;
  }
}

@primary-color: #121958;