@import "../../../style/variables.less";

// .customer-list-container {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 16px;
// }

.search-container {
  display: flex;
}
.advance-search-button {
  background: @primary-color;
  font-size: 18px;
  color: white;
  height: 32px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
}

@primary-color: #121958;