.login-card {
  width: 345px;
  height: 411px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  .ant-card-body {
    padding: 24px 32px 40px !important;
  }
  .ant-form-item-label > label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 21px;
    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
    color: #111111;
  }
  .ant-form-item-label {
    padding: 0px 0px 7px !important;
  }
  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .login-input {
    background: #fafafa;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 2px;
    height: 40px;
  }
  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f !important;
  }
}

.login-btn {
  position: absolute;
  width: 181px;
  height: 45px;
  top: 50%;
  left: 50%;
  background: #eb516d;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  margin-top: 16px !important;
  border-color: #fafafa !important;
  font-weight: 700;
}

.login-btn:hover,
.login-btn:focus {
  color: #fff !important;
  background: #eb516d !important;
  border-color: #fafafa !important;
}

@primary-color: #121958;