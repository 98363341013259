@import "./variables.less";

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  font-family: Noto Sans CJK JP, serif;
}

.main {
  min-height: 100vh;
  position: relative;
  background-size: cover;
}

.section {
  margin-top: 110px;
  margin-left: 280px;
}

.section-collapsed {
  margin-left: 50px;
}

.invalid-feedback {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 12px;
  color: #eb516d;
}

.full-backdrop {
  background: #000000;
  opacity: 0.5;
  position: absolute;
  width: 100vw;
  height: 150%;
  z-index: 900;
  top: 0;
  bottom: 0;
}

.form-container {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 50px;
  padding-bottom: 65px;
}

.button {
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  border: none;
  cursor: pointer;
  &-primary {
    color: @white;
    background: @success-color;
  }
  &-dark-blue {
    color: @white;
    background: @primary-color;
  }
  &-disabled {
    color: @white;
    background: #a2a5bd;
    cursor: not-allowed !important;
  }
  &-white {
    color: #888888;
    background: @white;
    width: 120px !important;
    border: 1px solid #888888 !important;
  }
}
.section-collapsed {
  .detail-view {
    @media screen and (max-width: 800) {
      display: none !important;
    }
    @media screen and (min-width: 800px) {
      flex: 0 0 280px !important;
      display: block !important;
    }
    @media screen and (min-width: 950px) {
      flex: 0 0 360px !important;
      display: block !important;
    }
  }
}

.detail-view {
  @media screen and (max-width: 950px) {
    display: none;
  }
  @media screen and (min-width: 950px) {
    flex: 0 0 320px;
    display: block;
  }
  @media screen and (min-width: 1200px) {
    flex: 0 0 360px;
    display: block;
  }
}

.button {
  display: inline-block;

  border-radius: 2px;
  text-align: center;
  border: none;
  cursor: pointer;

  &-primary {
    color: #fff;
    background: #eb516d;

    &-1 {
      width: 212px;
      height: 40px;
    }

    &-2 {
      height: 45px;
      width: 181px;
      // box-shadow: 0px 10px 20px rgb(0 0 0 / 5%);
      border-radius: 5px;
    }
  }
  &-default {
    background: #d8d8d8;
    color: #fff;

    &-1 {
      width: 212px;
      height: 40px;
      padding: 5px 0px;
    }

    &-2 {
      height: 45px;
      width: 119px;
      // box-shadow: 0px 10px 20px rgb(0 0 0 / 5%);
      border-radius: 5px;
    }
  }
}

.full-screen-spin {
  max-height: 100vh !important;
}

/* ----------- iPads and Iphone----------- */

.responsiveIphone() {
  .form-container {
    padding: 42px 15px 95px 15px;
    .form-header {
      margin-left: 0px !important;
    }
  }
  .detail-view-responsive-btn {
    display: flex !important;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(80px);
    height: 70px;
    padding: 13px 30px;
  }
  .detail-view {
    display: none !important;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: landscape) {
  .responsiveIphone();
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) and (orientation: portrait) {
  .responsiveIphone();
}
