.title-confirm-mail {
  font-size: 14px;
  width: 100%;
  margin: 1rem auto;
  @media (min-width: 1800px) {
    margin: 1rem 19.5rem;
    width: 80%;
  }
  @media (max-width: 500px) {
    width: 100% !important;
    margin: 1rem 0;
  }
  @media (max-width: 1700px) {
    width: 70%;
  }
}

.mail-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  @media (max-width: 475px) {
    width: 100%;
  }
}

.mail-content {
  font-size: 14px;
  max-width: 100%;
  height: 200px;
  overflow-y: scroll;
  overflow-wrap: break-word;
  background-color: rgb(231, 231, 231);
  padding: 1rem;
  border: 1px solid black;
  white-space: pre-line;
}
.mail-content-fail {
  font-size: 14px;
  max-width: 100%;
  height: 200px;
  overflow-y: scroll;
  background-color: rgb(231, 231, 231);
  padding: 1rem;
  border: 1px solid black;
  white-space: pre-line;
  text-align: center;
  padding: 3rem;
}

.mail-content-title {
  width: 100%;
  border: 1px solid black;
  background-color: rgb(190, 187, 187);
  padding: 0.2rem 0.5rem;
  color: #121958;
}

.reload-mail-button {
  background-color: transparent;
  border: transparent;
  margin: 0 0 0 0.5rem;
  cursor: pointer;
}

.mail-title-span {
  color: black;
  font-size: 10px;
}

@primary-color: #121958;