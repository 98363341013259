.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 110px;
  background-color: rgba(0, 0, 0, 0.5);
}

@primary-color: #121958;