@import "../../../style/variables.less";

.custom-heading {
  font-family: Noto Sans CJK JP;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: @link-color;
  cursor: text;
}

.master-heading {
  font-family: Noto Sans CJK JP;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 24px;
  color: #121958;
  padding-top: 4px;
}

.sub-heading {
  font-family: Noto Sans CJK JP;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 24px;
  color: #121958;
  padding-top: 4px;
}

@primary-color: #121958;