@import "../../../style/variables.less";

.badge {
  height: 34px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  z-index: 260;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Noto Sans CJK JP;
  &-tag {
    height: 38px;
    flex-direction: column;
    align-items: start;
    .upper-tag {
      display: flex;
      transform: translate(8px, -8px);
      background: inherit;
      border-color: inherit;
      border-width: 1px;
      border-style: solid;
      overflow: hidden;
      align-items: center;
      padding: 4px;
      max-width: calc(100% - 16px);
      height: 14px;
      &__content {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 2;
        font-size: 10px;
      }
      .svg-background {
        z-index: 1;
      }
    }
    .title-container {
      transform: translate(0, -4px);
    }
  }
  .title-container {
    display: flex;
    z-index: 20;
    width: 100%;
    padding: 0 8px;
    justify-content: flex-start;
    align-items: center;
    .title {
      font-size: 14px;
      font-weight: 700;
      padding-left: 10px;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .secondary-title {
      font-size: 12px;
      font-weight: 400;
    }
    .note-icon {
      position: absolute;
      right: 8px;
      bottom: 0px;
      min-width: 14px;
      min-height: 14px;
    }
    .icon {
      min-width: 14px;
      min-height: 14px;
    }
  }
  .svg-background {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .tag {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    top: -11px;
    font-size: 12px;
    color: #ffffff;
    font-weight: bold;
    padding: 0px 10px;
    background: @success-color;
    border-radius: 50px;
  }
}

@primary-color: #121958;