@import "../../../style/variables.less";

.container {
  min-height: 100vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-bottom: 40px;

  .net-form {
    max-width: 1100px;
    margin: 0 auto;
    padding-top: 50px;
    .net-link {
      width: 100%;
      text-align: center;
      padding-top: 30px;
      color: blue;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
      }
    }
    h1,
    h2 {
      color: @primary-color;
    }
  }
}

.responsiveNetRes() {
  .input-element-visit-time {
    width: 50%;
  }
  .res-form-wrapper {
    padding-left: 15px !important;
    margin-right: 15px !important;
  }
  .form-calendar {
    max-width: 100%;
    height: 555px !important;
  }
  .form-calendar .form-calendar-overlay button, .form-seat-button {
    font-size: 14px !important;
  }
  .input-element-phone {
    width: 212px !important;
  }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: landscape) {
  .responsiveNetRes();
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) and (orientation: portrait) {
  .responsiveNetRes();
}

@primary-color: #121958;