.form-header {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  margin-left: 0;

  font-size: 28px;
  font-weight: 700;
  line-height: 24px;
  color: #121958;
  span {
    padding-left: 6px;
  }
}

@primary-color: #121958;