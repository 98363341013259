@import "../../../style/variables.less";

.ant-list {
  height: 195px;
  overflow: auto;
}
.ant-list-item {
  &:nth-child(odd) {
    background-color: #fafafa;
  }
}

.ant-checkbox-input {
  width: 40px;
}

.list-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .list-left {
    max-width: 75%;
  }
}

.ant-picker {
  height: 40px;
  width: 212px;
  background: #fafafa;
  .anticon {
    color: @text-color;
  }
  input {
    // width: 212px;
    &::placeholder {
      color: #bbbbbb;
    }
  }
}

.time-picker {
  width: 90px;
  height: 40px;
}

.ant-input-custom {
  height: 40px;
  width: 100%;
  max-width: 212px;
  min-width: 212px;
  background: #fafafa;
  &::placeholder {
    color: #bbbbbb;
  }
}

.number-input {
  width: 70px;
  background: #fafafa;
  height: 40px;
}

.radio-button-container {
  display: flex;
  // justify-content: space-between;
}

.radio-button-container-flex-start {
  display: flex;
  justify-content: flex-start;
}

.input-elements-inline {
  .invalid-feedback {
    position: static;
    display: block;
    max-width: 160px;
  }
}

.ant-radio-button-wrapper-checked {
  background: @success-color!important;
  color: #fff !important;
  border: none !important;
  &::before {
    background: #fff !important;
  }
}

.button-radio {
  display: inline-block;
  border-radius: 2px;
  text-align: center;
  border: none;
  cursor: pointer;
  width: 30%;
  // max-width: 212px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
  min-height: 40px;
  position: relative;

  &:hover,
  &:active {
    color: #fff;
    background: @success-color;
  }

  &-default {
    background: @error-color;
    color: #fff;
  }

  &-primary {
    color: #fff;
    background: @success-color;
  }

  background: #d8d8d8;
  color: #fff;

  &::before {
    background: #fff !important;
  }

  span:not(.ant-radio-button) {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: calc(50% - 15px);
  }
}

.textarea-custom {
  width: 100%;
}

.number-of-people-input {
  height: 40px;
  line-height: 40px;
  .ant-select-item-option-content {
    font-size: 12px !important;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 40px;
  padding: 5px 11px;
}

.number-of-people-input::-webkit-outer-spin-button,
.number-of-people-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-phone-menu {
  top: 30px !important;
  width: 224px;
  height: 292px;
  display: flex;
  background: #ffffff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16) !important;
  border-radius: 5px;
  padding: 24px;
  border: 1px solid #d8d8d8;
  flex-wrap: wrap;
  margin: 0 -5px;
  li {
    clear: both;
    margin: 5px;
    color: #111111;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: all 0.3s;
    white-space: normal;
    padding: 0px !important;
    width: 48px;
    height: 48px !important;
    line-height: 44px;
    text-align: center;
    border: 1px solid #535353;
    color: #535353;
    background: #fff;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.18) !important;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.18) !important;
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #ffffff !important;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ffffff !important;
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #ffffff im !important;
}

.image-upload {
  display: flex;
  align-items: flex-start;
  .file-button {
    height: 40px;
    width: 180px;
    margin-right: 40px;
  }
  .image-preview-container {
    position: relative;
    .close-container {
      background: #fff;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      right: 5px;
      z-index: 100;
      padding: 2px 4px;
      font-size: 10px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    }
  }
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px !important;
}

/* ----------- iPads----------- */
.responsiveFormCtrl() {
  .custom-phone-menu {
    left: 145px !important;
    top: 44px !important;
    z-index: 9999;
  }
  .ant-input-custom {
    max-width: 100% !important;
  }
  .staff-phone-number {
    width: 100% !important;
  }
  .staff-email {
    width: 100% !important;
  }
  .reservation-input-name {
    flex-direction: column;
    div {
      padding-bottom: 6px !important;
    }
    div:last-child {
      padding-bottom: 0px !important;
    }
  }
  .input-element {
    margin-right: 0 !important;
    .ant-select {
      width: 100% !important;
    }
    .time-picker {
      width: 90px !important;
      margin-bottom: 16px;
    }
    .ant-picker {
      width: 100% !important;
    }
    .radio-button-container,
    .radio-button-container-flex-start {
      flex-direction: column;
      .button-radio {
        width: 100% !important;
        max-width: 100% !important;
        margin-bottom: 16px;
      }
      :last-child {
        margin-bottom: 0px;
      }
    }
    .radio-button-pointer {
      .ant-radio-wrapper {
        white-space: pre-wrap;
      }
    }
  }
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: landscape) {
  .responsiveFormCtrl();
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) and (orientation: portrait) {
  .responsiveFormCtrl();
}

@primary-color: #121958;