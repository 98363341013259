.line-button{
    background: #54C884;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    height: 45px;
    span{
        font-family: Noto Sans CJK JP;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        text-decoration-line: underline;
        color: white;
    }
}
.line-button:hover, .line-button:focus, .line-button:active{
    background: #54C884 !important;
    border-color: white !important;
    span{
        color: white !important;
    }
}
@primary-color: #121958;