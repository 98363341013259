.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

.sortable-table {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  .ant-table-thead {
    thead {
      background: #fbfbfb;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      border-radius: 5px 5px 0px 0px;
    }
    th {
      font-family: Noto Sans CJK JP;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      color: #111111;
      background: #fbfbfb !important;
      border-bottom: 1px solid #dddddd !important;
    }
  }
  .ant-table-thead > tr > th {
    padding: 10px 0px 12px 24px !important;
  }
  .ant-table-tbody > tr > td {
    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #111111;
    // padding: 24px 0px 24px 24px !important;
    height: 67px;
    padding-left: 24px !important;
    border-bottom: 1px solid #d8d8d8 !important;
  }
  .ant-table-tbody > tr > td > span {
    display: flex;
    justify-content: center;
  }
  .ant-table-body {
    overflow-y: auto !important;
    max-height: 680px !important;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 5px #d8d8d8;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    border-radius: 10px;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  //   background: grey;
  // }
}

.table-search {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 32px;
  height: 40px;
  width: 252px;
  float: right;
}

.ant-modal-mask {
  background-color: #00000040 !important;
}

.add-btn {
  height: 30px;
  background: #121958;
  border-radius: 4px;
  width: 110px;
  padding: 2px 0px 4px 10px;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: white;
    font-family: Noto Sans CJK JP;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    padding-right: 8px;
  }
}
.add-btn:hover,
.add-btn:focus,
.add-btn:active {
  background: #121958;
}

.table-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

@primary-color: #121958;