@import "../../../style/variables.less";

.hiragana-list-radio {
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: @primary-color !important;
  }
  .ant-radio-button-wrapper {
    flex-basis: 100%;
    text-align: center;
    font-family: @font-japanese;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid @primary-color !important;
  }

  .ant-radio-button-wrapper-checked {
    background-color: @primary-color!important;
    color: @white !important;
    border: none;
    &:focus {
      outline: none;
    }
  }
}
.responsiveFormCtrl() {
  .hiragana-list-radio {
    .ant-radio-button-wrapper {
      &:nth-child(11) {
        width: 20% !important;
      }
    }
  }
}
/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: landscape) {
  .responsiveFormCtrl();
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) and (orientation: portrait) {
  .responsiveFormCtrl();
}
@primary-color: #121958;