@import "../../../../style/variables.less";

.ant-table-thead {
  th {
    background: #efefef !important;
  }
}

// .store-dropdown {
//   .ant-dropdown-menu {
//     overflow: hidden !important;
//   }
// }

// .ant-table-tbody > tr > td {
//   padding-top: 0px !important;
// }
.count-reservation {
  height: 24px;
  font-family: Noto Sans CJK JP;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 24px;
  color: @link-color;
  float: right;
  padding: 0px !important;
  & span {
    width: 27px;
    height: 24px;
    left: 1074px;
    top: 144px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 24px;
    text-align: center;
    color: @success-color;
    padding: 0px 4px;
  }
}

@primary-color: #121958;