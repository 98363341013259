[ant-click-animating-without-extra-node]:after {
  -webkit-animation: none !important;
  -moz-animation: none !important;
  -o-animation: none !important;
  -ms-animation: none !important;
  animation: none !important;
}

.antLayoutSiderCollapsed() {
  display: none !important;
}

.antLayoutSider() {
  top: 0px;
  z-index: 3000;
  background: #121958;
  min-width: 100% !important;
  .ant-menu-custom {
    background-color: #121958;
  }
  .menu-icon {
    background: #121958 !important;
    border-color: #121958 !important;
  }
  .ant-menu-custom span {
    color: white;
  }
  .ant-menu-custom .ant-menu-item {
    width: 100% !important;
  }
  .ant-menu-sub.ant-menu-inline {
    background-color: #121958 !important;
  }
  .ant-menu-inline .ant-menu-submenu-title {
    width: 100% !important;
  }
  .ant-menu-submenu-selected .ant-menu-submenu-title span {
    color: white !important;
  }
  .header {
    height: 70px !important;
    padding: 0px 15px;
  }
}

.responsiveIphone() {
  .section {
    margin-left: 0px !important;
  }
  .ant-layout-sider-collapsed {
    .antLayoutSiderCollapsed();
  }
  .ant-layout-sider {
    .antLayoutSider();
  }
}
/* ----------- iPhone X ----------- */

// @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
//   .responsiveIphone();
// }

// @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
//   .responsiveIphone();
// }

/* ----------- iPads----------- */

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: landscape) {
  .responsiveIphone();
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) and (orientation: portrait) {
  .responsiveIphone();
}

@primary-color: #121958;