.ant-modal-confirm .ant-modal-confirm-btns button + button {
  color: #ff4d4f !important;
  background: #fff !important;
  border-color: #ff4d4f !important;
}

.ant-message-success .anticon {
  color: #52c41a;
}

.ant-message-error .anticon {
  color: #ff4d4f;
}

.list-container {
  margin: 0px 40px;
  padding: 49.17px 0px 120px 0px;
}

.responsiveListIphone() {
  .list-container {
    margin: 0px !important;
  }
  .responsive-btn {
    position: fixed;
    height: 70px;
    width: 100%;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(80px);
    padding: 13px 30px;
    z-index: 2;
    button {
      height: 100%;
      width: 100%;
    }
  }
  .form-header {
    margin-left: 15px !important;
  }
  .table-search {
    width: 100% !important;
    margin: 0px 15px;
  }
  .table-header-container {
    margin-bottom: 24px !important;
  }
  .sp-reservation-list-header {
    margin: 0px 15px 24px !important;
    flex-direction: column !important;
    div:first-child {
      max-width: 100%;
      .sub-heading {
        width: 100%;
      }
    }
    div:last-child {
      max-width: 100%;
      .ant-picker {
        width: 100% !important;
        margin-top: 20px;
      }
    }
  }
  .hiragana-list-radio {
    margin: 0px 15px;
    label {
      height: 30px !important;
      width: 30px !important;
      font-size: 14px !important;
      padding: 0px;
    }
    label:last-child {
      width: 75px !important;
    }
  }
}
/* ----------- iPhone X ----------- */

// @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: landscape) and (-webkit-device-pixel-ratio: 3) {
//   .responsiveListIphone();
// }

// @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (orientation: portrait) and (-webkit-device-pixel-ratio: 3) {
//   .responsiveListIphone();
// }

/* ----------- iPads----------- */

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (orientation: landscape) {
  .responsiveListIphone();
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 1023px) and (orientation: portrait) {
  .responsiveListIphone();
}

@primary-color: #121958;