.mail-copy {
  font-size: 14px;
  width: 70%;
  margin: 1rem auto;
  @media (min-width: 1800px) {
    margin: 1rem 20rem;
  }
}

.registration-email-title {
  color: #121958;
  font-size: 16px;
  text-align: center;
  margin-bottom: 2rem;
  font-weight: bold;
}

.copy-button {
  background-color: #121958;
  border: transparent;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  padding: 0.5rem 3rem;
  margin-left: 0.3rem;
  text-align: left;
  cursor: pointer;
  min-width: 300px;
  @media (max-width: 475px) {
    max-width: 90px;
    min-width: 200px;
    text-align: center;
  }
}

.link-to-web {
  display: flex;
  margin-left: -18px;
}

.link-to-gnavi {
  color: white;
}

.link-to-gnavi:hover {
  color: white;
}

.footer-title {
  color: #acacb1;
}

@primary-color: #121958;